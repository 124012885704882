import ConfigApi from '@/api/ConfigApi'
import ProjectApi from '@/api/ProjectApi'
import { useCookies } from 'vue3-cookies'
import { useUserGlobalStore } from './user'
import { find, flatten, isEmpty } from 'lodash-es'
import { ProjectDetailType } from '#/ProjectDetailTypes'
import { ProjectPermissionLevel, ProjectReviewStatus } from '#/ProjectTypes'
import { LanguagesType, ProjectSubscribePlanType } from '#/GlobalConfigTypes'

const { cookies } = useCookies()

export const useGlobalStore = defineStore({
  id: 'Global',
  state: () => {
    const globalState: {
      loaded: boolean
      languages: LanguagesType | null
      projectList: ProjectDetailType[]
      showUpGradeInfo: boolean
      projectPlanIntroduceInfo: ProjectSubscribePlanType
    } = {
      languages: null, // 语言包
      loaded: true,
      projectList: [],
      showUpGradeInfo: false,
      projectPlanIntroduceInfo: {
        introduce: [],
        subscriptionPlans: []
      }
    }
    return globalState
  },

  actions: {
    // 获取配置
    async globalConfigInit() {
      this.loaded = true
      let globalConfig: any
      if (this.languages === null) {
        globalConfig = new Promise((resolve, reject) => {
          ConfigApi.getGlobalConfig()
            .then((response) => {
              this.languages = response.data.languages
              this.projectPlanIntroduceInfo = response.data?.plan
              return resolve(response.data)
            })
            .catch((e) => {
              return reject(e)
            })
        })
      }
      const token = cookies.get('elv-app-token')
      let projectList: any
      if (token) {
        projectList = new Promise((resolve, reject) => {
          ProjectApi.getProjectList()
            .then((response) => {
              this.projectList = response.data
              return resolve(response.data)
            })
            .catch((e) => {
              return reject(e)
            })
        })
      }
      const userGlobalStore = useUserGlobalStore()

      try {
        let requestList = [globalConfig]
        if (isEmpty(userGlobalStore.user) && token && this.languages === null) {
          requestList = [globalConfig, projectList, userGlobalStore.userInit()]
        } else if (token && this.languages === null) {
          requestList = [globalConfig, projectList]
        } else if (token && this.languages !== null) {
          requestList = [projectList]
        }
        const response = await Promise.all(requestList)
        return response
      } catch (error: any) {
        return Promise.reject(error)
      } finally {
        this.loaded = false
      }
    },
    /**
     * 获取项目列表
     */
    async fetchProjectList() {
      try {
        const response = await ProjectApi.getProjectList()
        this.projectList = response.data
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  getters: {
    entityListAll: (state) => {
      // 使用lodash将state.projectList所有中的entityList合并
      const a = flatten(state.projectList.map((item) => item?.entityList ?? []))
      return a
    },
    projectDetail: (state) => (projectId: string) => {
      return find(state.projectList, { projectId })
    },
    unActiveFreeProject: (state) => (projectId: string) => {
      const freeProject = state.projectList.find(
        (project) =>
          project.projectId === projectId &&
          project.plan === ProjectPermissionLevel.FREE &&
          project.projectReview?.status === ProjectReviewStatus.UNACTIVATION
      )
      if (!isEmpty(freeProject)) {
        return freeProject
      }
      return null
    },
    // 获取当前项目是否为免费项目且处于待更新项目信息或已更新项目信息阶段
    freeProjectUpdateInfo: (state) => (projectId: string) => {
      if (!projectId) return false
      const currentProject = find(state.projectList, { projectId })
      if (!currentProject) return false
      const list: string[] = [ProjectReviewStatus.WAIT_DATA_UPDATE, ProjectReviewStatus.DATA_UPDATE]
      const isUpdateProjectInfo =
        currentProject?.plan === ProjectPermissionLevel.FREE &&
        list.includes(currentProject.projectReview?.status || '')
      return isUpdateProjectInfo
    }
  }
})
